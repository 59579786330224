import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/ProjectGrid.css';

const ProjectGrid = ({ projects }) => {
    return (
        <div className="project-grid">
            {projects.map(project => (
                <div key={project.id} className="project-grid-item">
                    <Link to={`/project/${project.id}`}>
                        <img src={project.image} alt={project.title} className="project-image" />
                        <div className="project-info">
                            <h3>{project.title}</h3>
                            <p>{project.description}</p>
                        </div>
                    </Link>
                </div>
            ))}
        </div>
    );
};

export default ProjectGrid;
