import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import ProjectPage from './pages/ProjectPage.js';
import AllProjects from './pages/AllProjects';
import Software from './pages/Software';
import Art from './pages/Art';
import Gamedev from './pages/Gamedev';
import Guides from './pages/Guides';

import Navbar from './components/Navbar.js'

import useGradientMovement from './hooks/useGradientMovement';

import './App.css';

function App() {

  useGradientMovement();

  return (
    <Router>
      <div>


        <Navbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects/:projectId" element={<ProjectPage />} />
          <Route path="/all-projects" element={<AllProjects />} />
          <Route path="/" element={<Home />} />
          <Route path="/software" element={<Software />} />
          <Route path="/art" element={<Art />} />
          <Route path="/gamedev" element={<Gamedev />} />
          <Route path="/guides" element={<Guides />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
