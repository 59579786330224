import React from 'react';
import ProjectGrid from '../components/ProjectGrid';
import projectsData from '../data/projectsData';

function Gamedev() {
    const guides = projectsData.filter(project => project.category === 'Guide');

    return (
        <div className="default-container">
            <h1>Guides and resources</h1>
            <ProjectGrid projects={guides} />
        </div>
    );
}

export default Gamedev;
