import React from 'react';
import ProjectGrid from '../components/ProjectGrid';
import projectsData from '../data/projectsData';

function Software() {
    const softwareProjects = projectsData.filter(project => project.category === 'Software');

    return (
        <div className="default-container">
            <h1>Software Projects</h1>
            <ProjectGrid projects={softwareProjects} /> {/* Use the ProjectGrid component */}
        </div>
    );
}

export default Software;
