const projects = [
    {/*}
    {
        id: 'project1',
        image: '/assets/image1.png',
        title: 'Bunny Socks',
        description: 'A cozy project about creating stylish and comfortable socks with bunny designs.',
        category: 'Software'
    },
    {
        id: 'project2',
        image: '/assets/image2.png',
        title: 'Bunny Socks II',
        description: 'An advanced project featuring a new range of bunny-themed socks with improved designs.',
        category: 'Gamedev'
    },
    */}
];

export default projects;
