// src/pages/ProjectPage.js
import React, { useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useParams } from 'react-router-dom';

function ProjectPage() {
    const { projectId } = useParams();
    const [content, setContent] = useState('');

    useEffect(() => {
        fetch(`/projects/${projectId}.md`)
            .then(response => {
                if (!response.ok) throw new Error('Network response was not ok.');
                return response.text();
            })
            .then(text => setContent(text))
            .catch(error => console.error('Error loading markdown:', error));
    }, [projectId]);

    return (
        <div>
            <h1>Project Details</h1>
            <ReactMarkdown>{content}</ReactMarkdown>
        </div>
    );
}

export default ProjectPage;