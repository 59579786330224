import { useEffect } from 'react';

function useGradientMovement() {
    useEffect(() => {
        const gradients = document.querySelectorAll('.spherical-gradient');
        const speed = 2; // Speed of movement
        const directionChangeRate = 0.02; // Direction change rate
        const colorChangeInterval = 5000; // Time in milliseconds for color change
        const bgChangeInterval = 10000; // Time in milliseconds for background gradient change

        function getRandomColor() {
            const r = Math.floor(Math.random() * 256);
            const g = Math.floor(Math.random() * 256);
            const b = Math.floor(Math.random() * 256);
            return `rgba(${r}, ${g}, ${b}, 0.6)`;
        }

        function animate() {
            gradients.forEach(gradient => {
                // Ensure gradient has initial values
                gradient.dataset.posX = gradient.dataset.posX || Math.random() * (window.innerWidth - 500); // Adjust for gradient size
                gradient.dataset.posY = gradient.dataset.posY || Math.random() * (window.innerHeight - 500); // Adjust for gradient size
                gradient.dataset.velX = gradient.dataset.velX || (Math.random() - 0.5) * speed;
                gradient.dataset.velY = gradient.dataset.velY || (Math.random() - 0.5) * speed;

                let posX = parseFloat(gradient.dataset.posX);
                let posY = parseFloat(gradient.dataset.posY);
                let velX = parseFloat(gradient.dataset.velX);
                let velY = parseFloat(gradient.dataset.velY);

                // Update position with velocity
                posX += velX;
                posY += velY;

                // Randomly change direction
                if (Math.random() < directionChangeRate) {
                    velX += (Math.random() - 0.5) * speed;
                    velY += (Math.random() - 0.5) * speed;
                }

                // Keep the gradient within bounds
                if (posX < 0 || posX > window.innerWidth - 500) velX *= -1; // Adjust for gradient size
                if (posY < 0 || posY > window.innerHeight - 500) velY *= -1; // Adjust for gradient size

                gradient.dataset.posX = posX;
                gradient.dataset.posY = posY;
                gradient.dataset.velX = velX;
                gradient.dataset.velY = velY;

                gradient.style.left = `${posX}px`;
                gradient.style.top = `${posY}px`;
            });

            requestAnimationFrame(animate);
        }

        function changeColors() {
            gradients.forEach(gradient => {
                const color1 = getRandomColor();
                const color2 = getRandomColor();
                gradient.style.setProperty('--gradient1', `radial-gradient(circle, ${color1}, transparent)`);
                gradient.style.setProperty('--gradient2', `radial-gradient(circle, ${color2}, transparent)`);
            });
        }

        function updateBackground() {
            const bgColor1 = getRandomColor();
            const bgColor2 = `rgba(0, 0, 0, 0.8)`; // Darker end of the gradient
            document.documentElement.style.setProperty('--bg-gradient', `linear-gradient(180deg, ${bgColor1}, ${bgColor2})`);
        }

        // Start the animation and color change
        animate();
        const colorChangeId = setInterval(changeColors, colorChangeInterval);
        const bgChangeId = setInterval(updateBackground, bgChangeInterval);

        // Cleanup function to clear intervals and animations
        return () => {
            clearInterval(colorChangeId);
            clearInterval(bgChangeId);
            cancelAnimationFrame(animate);
        };
    }, []);
}

export default useGradientMovement;
