// src/components/ProjectPreview.js
import React from 'react';
import PropTypes from 'prop-types';
import '../styles/ProjectPreview.css'; // Optional: for custom styling

function ProjectPreview({ image, title, description }) {
    return (
        <div className="project-preview">
            <img src={image} alt={title} className="project-preview-image" />
            <h2 className="project-preview-title">{title}</h2>
            <p className="project-preview-description">{description}</p>
        </div>
    );
}

ProjectPreview.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
};

export default ProjectPreview;
