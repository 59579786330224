import React from 'react';
import ProjectGrid from '../components/ProjectGrid';
import projectsData from '../data/projectsData';

function Gamedev() {
    const gamedevProjects = projectsData.filter(project => project.category === 'Gamedev');

    return (
        <div className="default-container">
            <h1>Gamedev Projects</h1>
            <ProjectGrid projects={gamedevProjects} />
        </div>
    );
}

export default Gamedev;
