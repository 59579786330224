import React from 'react';
import ProjectPreview from '../components/ProjectPreview';
import ProjectGrid from '../components/ProjectGrid';
import projects from '../data/projectsData';
import { Link } from 'react-router-dom';

import '../styles/Home.css'

function Home() {
    return (
        <div className="home-container">
            <h1>Pixel Niconi</h1>
            <p>Hello! I’m a software developer passionate about technologies and computational mathematics.
                Explore my portfolio to see a curated selection of projects. </p>
            <p>Please note that I’m currently updating the project pages, so your patience is appreciated.".</p>
            <div className="action-button">
                <Link to="/all-projects">
                    <button><span>All Projects</span></button>
                </Link>
            </div>

        </div>
    );
}

export default Home;
